<template>
  <navBar :list="navlist" name="市场列表"></navBar>
  <div class="top-box">
    <div class="item">
      <div>{{statisForm.obj.total}}</div>
      <div>{{ topDateType }}总收:</div>
    </div>
    <div class="item">
      <div style="color: #3D7EFF;">{{statisForm.obj.charge}}</div>
      <div>{{ topDateType }}总手续费:</div>
    </div>
    <div class="item">
      <div style="color:#666666">{{statisForm.obj.count}}</div>
      <div>{{ topDateType }}总笔数:</div>
    </div>
    <div class="item">
      <div style="color: #02CE80;">{{statisForm.obj.height}}</div>
      <div>{{ topDateType }}单笔最高:</div>
    </div>
    <div class="item item-date">
      <el-date-picker
        name="date"
        style="width: 500px;"
        id="date"
        @change="topDateChange"
        v-model="topDate"
        type="daterange"
        value-format="YYYY-MM-DD"
      ></el-date-picker>
    </div>
  </div>
  <div class="billrunwater">
    <vxe-grid
      ref="xGrid"
      class="my-grid66"
      v-bind="gridOptions"
      @checkbox-change="checkboxChangeEvent"
      @checkbox-all="checkboxChangeEvent"
      height="auto"
      border="inner"
    >
      <!--自定义插槽 toolbar buttons 插槽-->
      <template #toolbar_buttons>
        <div class="topForm">
          <my-input class="right" placeholder="请输入市场名称" v-model.trim="searchForm.market_name">
            <template #pre>市场名称</template>
          </my-input>
          <!-- <el-input v-model.trim="searchForm.store_name" placeholder="请输入店铺名称" clearable></el-input> -->
          <!-- <el-input v-model.trim="searchForm.store_no" placeholder="请输入店铺ID" clearable></el-input> -->
          <el-date-picker
            v-model="date"
            clearable
            placeholder
            type="daterange"
            value-format="YYYY-MM-DD"
            @change="dateChange"
          ></el-date-picker>
          <el-select
            placeholder="时间段选择"
            clearable
            @change="dateTypeChange"
            v-model="searchForm.date_type"
            class="right"
            style="margin-left:25px"
          >
            <el-option label="今日" value="today"></el-option>
            <el-option label="昨日" value="yesterday"></el-option>
            <el-option label="近一个月" value="nearlyMonth"></el-option>
          </el-select>
          <myButton status="primary" @click="getList" class="right" title="搜索">
            <template #preImage>
              <img src="@/assets/search.png" alt />
            </template>
          </myButton>
          <myButton status="primary" class="right" @click="printEvent1" title="打印">
            <template #preImage>
              <img src="@/assets/dy.png" alt />
            </template>
          </myButton>
          <myButton status="primary" class="right" @click="printSelectEvent1" title="打印勾选">
            <template #preImage>
              <img src="@/assets/dy.png" alt />
            </template>
          </myButton>
          <myButton status="primary" class="right" @click="exportDataEvent" title="导出">
            <template #preImage>
              <img class="icon" src="@/assets/down.png" alt />
            </template>
          </myButton>
          <myButton status="primary" class="right" @click="exportSelectEvent" title="导出选中">
            <template #preImage>
              <img class="icon" src="@/assets/down.png" alt />
            </template>
          </myButton>
        </div>
      </template>

      <!--自定义插槽 toolbar tools 插槽-->
      <template #toolbar_tools>
        <!-- <vxe-input placeholder="搜索"></vxe-input> -->
      </template>

      <!--使用 top 插槽-->
      <!-- <vxe-table>
        <vxe-column field="oper" title="Html片段" width="200" show-overflow>
            <template #default="{ row }">
              <span v-html="row.oper"></span>
            </template>
            <template #footer>
              <span>
                <img src="https://pic2.zhimg.com/50/v2-f7031359103859e1ed38559715ef5f3f_hd.gif" style="width: 36px;">自定义模板<img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" style="width: 30px;">
              </span>
            </template>
          </vxe-column>
      </vxe-table>-->

      <template #num_default="{ row, rowIndex }">
        <template v-if="rowIndex === 2">
          <vxe-switch v-model="row.flag"></vxe-switch>
        </template>
        <template v-else-if="rowIndex === 3">
          <vxe-switch v-model="row.flag" open-label="开" close-label="关"></vxe-switch>
        </template>
        <template v-else>
          <span>{{ row.num1 }}</span>
        </template>
      </template>
      <template #num_footer="{ items, _columnIndex }">
        <span style="color: red">合计：{{ items[_columnIndex] }}</span>
      </template>

      <template #num1_default="{ row }">
        <span>￥{{ row.num1 }}元</span>
      </template>

      <template #num1_header="{ column }">
        <span>
          <i>@</i>
          <span style="color: red;" @click="headerClickEvent">{{ column.title }}</span>
        </span>
      </template>

      <template #num1_footer="{ items, _columnIndex }">
        <span>
          <vxe-button status="primary" size="mini">按钮</vxe-button>
          <span>累计：{{ items[_columnIndex] }}</span>
        </span>
      </template>

      <template #num1_filter="{ column, $panel }">
        <div>
          <div v-for="(option, index) in column.filters" :key="index">
            <input
              type="type"
              v-model="option.data"
              @input="changeFilterEvent($event, option, $panel)"
            />
          </div>
        </div>
      </template>

      <template #num1_edit="{ row }">
        <input type="number" class="my-input" v-model="row.num1" />
      </template>

      <template #img1_default="{ row }">
        <img v-if="row.img1" :src="row.img1" style="width: 100px;" />
        <span v-else>无</span>
      </template>

      <!--自定义插槽 pager 插槽-->
      <template #pager>
        <div class="public-page">
          <el-pagination
            :total="total"
            next-text="下一页"
            prev-text="上一页"
            @current-change="getList"
            layout="total,prev,pager,next"
            :page-size="searchForm.pageSize"
          ></el-pagination>
        </div>
      </template>
    </vxe-grid>

    <vxe-modal v-model="demo1.showDetails" title="查看详情" width="800" height="400" resize>
      <template #default>
        <div v-if="demo1.selectRow" v-html="demo1.selectRow.html3"></div>
      </template>
    </vxe-modal>
  </div>
</template>
<script >
import {
  defineComponent,
  reactive,
  ref,
  inject,
  nextTick,
  onMounted
} from 'vue';
import {
  useRouter
} from 'vue-router'
import {
  VXETable
} from 'vxe-table'

export default defineComponent({
  setup() {
    const xGrid = ref({})
    const vm = inject("plugin");
    const router = new useRouter();

    nextTick(() => {
      let dateInput = document.querySelector('.el-range-input');
      dateInput.style.display = 'none'
      let datePicker = document.querySelector('.el-range-separator');
      datePicker.innerHTML = '历史'
    })
      onMounted(()=>{
     getStatistics()
    })
    // 获取统计数据
       let statisForm = reactive({obj:{
         total:'-',
         charge:'-',//手续费
         count:'-',
         height:'-'
       }})
      let statisSearchaForm = reactive({
        end_at:'',
        start_at:''
      })
    const getStatistics = () =>{
       vm.HttpPost('/admin/summary/enterprise ',statisSearchaForm)
      .then(res =>{
        if(res.data){
          statisForm.obj = res.data
        }else{
          for(let i in statisForm){
            statisForm[i] = '-'
          }
        }
      })
    }
    // 顶部时间切换
        // 头部
    // 时间筛选
    let topDate = ref('历史');
    let topDateType = ref('历史')
    const topDateChange = (e) => {
      if (e) {
        statisSearchaForm.start_at = e[0];
        statisSearchaForm.end_at = e[1]
        nextTick(() => {
          let dateInput = document.querySelector('.el-range-input');
          dateInput.style.display = 'block'
          let datePicker = document.querySelector('.el-range-separator');
          datePicker.innerHTML = '-';
          topDateType.value = '当前'
        })
      } else {
         statisSearchaForm.start_at = '';
        statisSearchaForm.end_at = ''
        nextTick(() => {
          let datePicker = document.querySelector('.el-range-separator');
          let dateInput = document.querySelector('.el-range-input');
          dateInput.style.display = 'none'
          datePicker.innerHTML = '历史';
          topDateType.value = '历史'
        })
      }
      getStatistics()
    }
    const addressClickEvent = (row) => {
      router.push(`marketbillRunWater/merchangeRunWater?id=${row.MarketNo}&name=${row.marketName}&date_type=${searchForm.date_type}`)
    }
    let navlist = []
    const demo1 = reactive({
      showDetails: false,
      selectRow: null,
      isAllChecked: false,
      isIndeterminate: false,
      selectRecords: [],
      formData: {
        name: '',
        nickname: '',
        sex: ''
      },
      tablePage: {
        totalResult: 8,
        currentPage: 1,
        pageSize: 10
      }
    })

    const gridOptions = reactive({
      border: true,
      showFooter: true,
      height: 600,
      columnConfig: {
        resizable: true
      },
      editConfig: {
        trigger: 'click',
        mode: 'cell',
        icon: 'fa fa-pencil-square-o'
      },
      data: [],
      toolbarConfig: {
        custom: true,
        slots: {
          buttons: 'toolbar_buttons',
          tools: 'toolbar_tools'
        }
      },
      columns: [{
        fild: 'check',
        type: 'checkbox',
        title: '选择',
        width: 80
      },
      // { field: 'name', title: 'Name', resizable: false, slots: { header: 'name_header' } },
       {
        field: 'MarketNo',
        title: '市场编号'
      },
      {
        field: 'marketName',
        title: '市场名称'
      },

      {
        field: 'marketContacts',
        title: '联系人'
      },
      {
        field: 'marketMobile',
        title: '联系电话'
      },
      {
        field: 'storeCount',
        title: '商户数',

      },
      {
        field: 'flowing_water',
        title: '流水金额',

      },
      {
        field: 'charge',
        title: '手续费',

      },
      {
        field: 'count',
        title: '笔数',

      },
      {
        field: 'marketName',
        title: '市场地址',
        slots: {
          // 使用 JSX 渲染
          default: ({
            row
          }) => {
            return [(<div style='text-align:left;padding-left:15px'>
             {row.marketAreaName + row.marketAddress}
            </div>)
            ]
          }
        }
      },
      {
        field: 'oper',
        title: '操作',
        showOverflow: true,
        slots: {
          // 使用 JSX 渲染
          default: ({
            row
          }) => {
            return [(<div class="popbox">
              <pop tips="商户流水明细">
                <img class="popIcon" onClick={() => addressClickEvent(row)} src={require("@/assets/xq.png")} />
              </pop>
            </div>)
            ]
          }
        }
      },
      ],
      footerMethod({
        columns,
        data
      }) {
        return [
          columns.map((column, index) => {
            if (['flowing_water', 'charge'].includes(column.property)) {

              return calcSum(data, column.property).toFixed(2)
            } else if (column.property == 'count') {
              return calcSum(data, column.property)
            } else if (index == 0) {
              return '合计'
            }

            return '/'
          }),
        ]
      }
    })
    const calcSum = (value, type) => {
      if (!value || !Array.isArray(value)) {
        return '/'
      }

      let total = value.reduce((totals, item) => {
        totals += Number(item[type]);
        return totals
      }, 0)
      return total
    }
    const searchEvent = () => {
      VXETable.modal.alert('查询')
    }

    const headerClickEvent = () => {
      VXETable.modal.alert('头部点击事件')
    }

    const changeFilterEvent = (event, option, $panel) => {
      $panel.changeOption(event, !!option.data, option)
    }

    const showDetailEvent = (row) => {
      demo1.selectRow = row
      demo1.showDetails = true
    }

    const checkboxChangeEvent = () => {
      const $grid = xGrid.value
      demo1.isAllChecked = $grid.isAllCheckboxChecked()
      demo1.isIndeterminate = $grid.isAllCheckboxIndeterminate()
      demo1.selectRecords = $grid.getCheckboxRecords()
    }

    const changeAllEvent = () => {
      const $grid = xGrid.value
      $grid.setAllCheckboxRow(demo1.isAllChecked)
      demo1.selectRecords = $grid.getCheckboxRecords()
    }
    // 时间改变
    let date = ref('')
    const dateChange = (value) => {
      if (value) {
        searchForm.start_at = value[0];
        searchForm.end_at = value[1];
        searchForm.date_type = '';
        printDateType.value = '今日'
      } else {
        searchForm.start_at = '';
        searchForm.end_at = '';
      }
      getList()
    }
    // 查看时间段选择
    const dateType = ref('')
    const dateTypeChange = (value) => {
      date.value = '';
      searchForm.end_at = '';
      searchForm.start_at = '';
      if (value) {
        printDateType.value = value == 'today' ? '今日' : value == 'yesterday' ? '昨日' : '近一个月'
      } else {
        printDateType.value = '今日'
      }
      getList()
    }

    let total = ref(0)
    const defaultTime2 = [
      new Date(2000, 1, 1, 0, 0, 0),
      new Date(2000, 2, 1, 24, 0, 0),
    ] // '12:00:00', '08:00:00'
    let searchForm = reactive({
      "page": 1,
      "pageSize": 20,
      start_at: "",
      "end_at": "",
      "store_no": "",
      "market_name": "",
      "date_type": "nearlyMonth"
    })
    const getList = (e = 1) => {
      searchForm.page = e
      vm.HttpPost('/admin/market ', searchForm)
        .then(res => {
          if (res.data) {
            if (res.data.list && res.data.list.length > 0) {
              res.data.list.forEach(item => {
                for (let i in item) {
                  item[i] = item[i].toString()
                }
              });
            }

            gridOptions.data = res.data.list;
            total.value = res.data.total
          }
        })
    }
    getList()

    // 打印
    // 打印样式
    const printStyle = `
    .sum-box{
      font-size:18px;
      margin-top:20px
    }
    .sum-box .num{
      color: #f84646;
      margin: 0 30px 0 10px;
      font-size: 20px;
    }
              `
    //顶部内容
    let allSum = ref(0) //总金额
    let sxfSum = ref(0)

    let printDateType = ref('今日')
    const printEvent1 = () => {
      let printTime = date.value ? `${searchForm.start_at}~${searchForm.end_at}` : printDateType.value;
      let topHtml = `<div style='display:flex;justify-content:space-between;padding:20px 20px;font-weight:bolder;font-size:25px'><span >市场流水列表</span><span>${printTime}</span></div>`
      let $table = xGrid.value
      $table.print({
        style: printStyle,
        columns: [{
          type: 'no'
        },
        {
          field: 'name_store'
        },
        {
          field: 'mobile_manager'
        },
        {
          field: 'flowing_water'
        },
        {
          field: 'charge'
        }
        ],
        beforePrintMethod: ({
          content
        }) => {
          // 拦截打印之前，返回自定义的 html 内容
          return topHtml + content
        }
      })
    }
    // 打印勾选行
    const printSelectEvent1 = () => {
      let printTime = date.value ? `${searchForm.start_at}~${searchForm.end_at}` : printDateType.value;
      let topHtml = `<div style='display:flex;justify-content:space-between;padding:20px 20px;font-weight:bolder;font-size:25px'><span >市场流水列表</span><span>${printTime}</span></div>`

      let $table = xGrid.value
      $table.print({
        sheetName: '打印勾选行',
        style: printStyle,
        mode: 'selected',
        columns: [{
          type: 'no'
        },
        {
          field: 'name_store'
        },
        {
          field: 'mobile_manager'
        },
        {
          field: 'flowing_water'
        },
        {
          field: 'charge'
        }
        ],
        beforePrintMethod: ({
          content
        }) => {
          // 拦截打印之前，返回自定义的 html 内容
          return topHtml + content
        }
      })
    }
    // 导出
    const exportDataEvent = () => {
      let $table = xGrid.value
      $table.exportData({
        type: 'csv',
        filename: `市场流水${new Date().toLocaleString()}`,
        columnFilterMethod({
          column
        }) {
          return ['no', 'name_store', 'mobile_manager', 'flowing_water', 'charge', 'count'].includes(column.property)
        }
      })
    }
    //导出选中
    const exportSelectEvent = () => {
      let $table = xGrid.value
      $table.exportData({
        data: $table.getCheckboxRecords(),
        filename: `市场流水${new Date().toLocaleString()}`,
        columnFilterMethod({ column }) {
          return ['no', 'name_store', 'mobile_manager', 'flowing_water', 'charge', 'count'].includes(column.property)
        }
      })
    }
    //导出全部
    const exportAllEvent = () => {
      vm.HttpPost()
    }


    return {
      xGrid,
      demo1,
      gridOptions,
      searchEvent,
      headerClickEvent,
      changeFilterEvent,
      showDetailEvent,
      checkboxChangeEvent,
      changeAllEvent,
      searchForm,
      defaultTime2,
      total,
      getList,
      dateChange,
      date,
      dateTypeChange,
      dateType,
      printEvent1,
      printSelectEvent1,
      exportDataEvent,
      exportSelectEvent,
      exportAllEvent,
      navlist,
      allSum,
      sxfSum,
      topDate,
      topDateChange,
      topDateType,
      statisForm
    }
  }
})
</script>
<style lang='scss' scoped>
.top-box {
  height: 98px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .item {
    width: 316px;
    height: 60px;
    border-right: 1px solid #f5f5f5;
    padding-left: 98px;
    box-sizing: border-box;
    &:last-child {
      border-right: none;
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
    span {
      color: #3d7eff;
      font-weight: bolder;
      font-size: 15px;
      cursor: pointer;
    }
    .el-date-picker {
      width: 10px;
    }
    /deep/ .el-input__inner {
      border: none;
      cursor: pointer;
    }
    input {
      cursor: pointer;
      font-size: 18px;
    }
    /deep/ .el-range-input {
      padding: 0 !important;
      flex-shrink: 0;
      width: 100px;
    }
    /deep/ .el-date-editor .el-range-separator {
      width: 10px !important;
      padding: 0;
      flex-shrink: 0;
      flex: 0.3;
      color: #3d7eff;
      font-weight: bolder;
      font-size: 18px;
      cursor: pointer;
    }
    div {
      &:nth-child(1) {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      &:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .item-date {
    // width: 100%;
    // background-color: red;
    padding: 0;
  }
}
.billrunwater {
  height: calc(100vh - 240px);
  padding-bottom: 50px;
  box-sizing: border-box;

  /deep/ .vxe-header--row {
    .vxe-header--column {
      height: 48px;
      margin: 0;
      padding: 0;
      .vxe-cell {
        text-align: center;
        background-color: rgb(245, 245, 245);
        height: 48px;
        line-height: 48px;
        border-bottom: none;
      }
    }
  }
  /deep/ .vxe-table--render-default .vxe-table--body-wrapper {
    height: 900px;
  }

  /deep/ .vxe-toolbar {
    align-items: flex-start;
  }
  /deep/ .vxe-body--row {
    &:nth-child(1) {
      border-top: none !important;
    }
    .vxe-body--column {
      background-color: #fdfdff;
      .vxe-cell {
        text-align: center;
      }
    }
  }

  /deep/ .vxe-footer--row {
    .vxe-footer--column {
      background-color: #e0e2e6;
      color: rgb(73, 73, 73);
    }
    .vxe-cell {
      text-align: center;
      // background-color: red;
    }
  }
}
.vxe-table--render-default.vxe-editable .vxe-body--column {
  height: 40px;
}
.topForm {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .right {
    margin-right: 25px;
  }
}
.my-grid66 {
}
.footer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 18px;
    &:nth-child(2n) {
      color: #f84646;
      margin: 0 30px 0 10px;
      font-size: 20px;
    }
  }
}
</style>
